<!-- 公共底部 -->
<template>
    <div id='footer'>
        <div class="container">
            <div class='containerTop' style="">
                <div class='quickLinks'>
                    <div class='title'>快速链接</div>
                    <ul class='tabList'>
                        <li @click='goHome()' :class="[tabActive=='1001'? 'liActive':'']">首页</li>
                        <li @click='goService()' :class="[tabActive=='1002'? 'liActive':'']">服务内容</li>
                        <li @click='goCase()' :class="[tabActive=='1003'? 'liActive':'']">案例展示</li>
                        <li @click='goAbout()' :class="[tabActive=='1004'? 'liActive':'']">关于我们</li>
                        <li @click='goCooperation()' :class="[tabActive=='1005'? 'liActive':'']">商务合作</li>
                    </ul>
                </div>
                <div class='contactUs'>
                    <div class='title'>联系我们</div>
                    <ul>
                        <li>全国免费服务热线：400-688-3939</li>
                        <li>在线时间：8:00—20:00</li>
                        <li>邮箱：bygarde001@163.com</li>
                        <li style="display: flex;line-height: 29px;">
                            <div>地址：</div>
                            <div>中国（苏州）自由贸易试验区苏州片区苏<br />
                                州工业园区星桂街33号凤凰国际大厦38F</div>
                        </li>
                    </ul>
                </div>
                <div class='appointService'>
                    <div class='title'>预约服务</div>
                    <div class='qrCode'>
                        <div style="margin-right: 48px;text-align: center;">
                            <img src='@/img/codeImg/bjdSubscription.png'>
                            <span>官方微信公众号</span>
                        </div>
                        <div style="text-align: center;">
                            <img src='@/img/codeImg/bjdApplet.png'>
                            <span>官方小程序</span>
                        </div>
                    </div>

                </div>
                <div>
                    <div class='title'>商务合作</div>
                    <div style="font-size: 36px;
                    font-family: Roboto-Bold Italic, Roboto;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 42px;margin: 40px 0 15px 0;font-style:italic">400-688-3939</div>
                    <div style="font-size: 16px;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: bold;
                    color: #E2E2E2;
                    line-height: 19px;margin-bottom: 32px;">在线时间：8:00—20:00</div>
                    <div style="width: 128px;height: 44px;background: #F53F59;border-radius: 4px 4px 4px 4px;font-size: 16px;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 44px;text-align: center;" @click='getClose'>预约服务</div>
                </div>
            </div>
        </div>
        <div class='footerBot' style="font-size: 12px;">
            Copyright©2019 柏嘉德（江苏）健康管理有限公司 www.bygarde.com <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备18038325号-2</a>
        </div>
        <Dialog v-if='isDialog' @closeDialog='closeDialog'></Dialog>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    import Dialog from '@/components/dialog.vue'
    export default {
        data() {
            return {
                data: "底部",
                tabActive: "1",
                isDialog: false

            }
        },
        //生命周期 - 创建完成（访问当前this实例）
        created() {

        },
        components: {
            Dialog
        },
        //生命周期 - 挂载完成（访问DOM元素）
        mounted() {
            this.tabActive = sessionStorage.getItem("currentTab")
        },
        watch: {
            "currentTab"() {
                this.tabActive = this.currentTab
            }

        },
        computed: {
            ...mapState({
                currentTab: state => state.currentTab
            }),
        },
        methods: {
            goHome() {
                this.$router.push({
                    path: '/',
                })
                sessionStorage.setItem("currentTab", "1001")
                this.$store.commit("SETT_CURRENTTBD", "1001")
                if (this.activeLi != "1001") {
                    this.activeLi = "1001"
                }
            },
            goService() {
                this.$router.push({
                    path: '/service/organService',
                })
                sessionStorage.setItem("currentTab", "1002")
                this.$store.commit("SETT_CURRENTTBD", "1002")
                if (this.activeLi != "1002") {
                    this.activeLi = "1002"
                }
            },
            goCase() {
                this.$router.push({
                    path: '/case',
                })
                sessionStorage.setItem("currentTab", "1003")
                this.$store.commit("SETT_CURRENTTBD", "1003")
                if (this.activeLi != "1003") {
                    this.activeLi = "1003"

                }
            },
            goAbout() {
                this.$router.push({
                    path: '/about',
                })
                sessionStorage.setItem("currentTab", "1004")
                this.$store.commit("SETT_CURRENTTBD", "1004")
                if (this.activeLi != "1004") {
                    this.activeLi = "1004"

                }
            },
            goCooperation() {
                this.$router.push({
                    path: '/cooperation',
                })
                sessionStorage.setItem("currentTab", "1005")
                this.$store.commit("SETT_CURRENTTBD", "1005")
                if (this.activeLi != "1005") {
                    this.activeLi = "1005"

                }
            },
            getClose() {
                if (!this.isDialog) {
                    this.isDialog = true
                }
            },
            closeDialog(value) {
                this.isDialog = value
            }
            // getQrCode() {
            //     if (!this.isQrCode) {
            //         this.isQrCode = true
            //     } else {
            //         this.isQrCode = false
            //     }
            // }

        }

    }
</script>
<style scoped>
    /* @import url(); 引入css类 */
    #footer {
        background: #202E42;
        padding-top: 50px;
        width: 1920px;
        position: relative;
        z-index: 2222;
    }

    #footer .container {
        display: flex;
        margin-left: 359px;
    }

    #footer .container .containerTop {
        padding-bottom: 44px;
        display: flex;
        border-bottom: 1px solid rgb(88, 98, 114);
    }

    #footer .container .quickLinks {
        margin-right: 92px;
    }

    #footer .container .contactUs {
        margin-right: 89px;
    }

    #footer .container .appointService {
        margin-right: 137px;
    }

    #footer .title {
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 27px;
        padding-bottom: 11px;
        border-bottom: 1px solid #586272;
        width: 74px;
    }

    #footer ul {
        margin-top: 40px;
    }

    #footer ul li {
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #E2E2E2;
        line-height: 24px;
        margin-bottom: 16px;
    }

    #footer .tabList li.liActive {
        color: #F53F59;
    }

    #footer .qrCode {
        display: flex;
        margin-top: 40px;
    }

    #footer .qrCode img {
        width: 100px;
        height: 100px;
        display: block;
        margin-bottom: 20px;
    }

    #footer .qrCode span {
        color: #ffffff;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
    }

    #footer .footerBot {
        padding: 20px 0 23px 0;
        text-align: center;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #8A8D90;
        line-height: 167%;
    }
    #footer .footerBot a{
        color: #8A8D90; 
    }
    #footer .footerBot a:hover{
        color:  #ff6a00!important
    }
</style>