<!-- 数字增加 -->
<template>
    <div class="number-grow-warp">
        <span ref="numberGrow" :data-time="time" class="number-grow" :data-value="value">0</span>
    </div>
</template>

<script>
    export default {
        props: {
            isShowClass: {
                type: Boolean,
                default: true
            },
            time: {
                type: Number,
                default: 1
            },
            value: {
                type: Number,
                default: 100
            }
        },
        data() {
            return {

            }
        },
        //生命周期 - 创建完成（访问当前this实例）
        created() {

        },
        //生命周期 - 挂载完成（访问DOM元素）
        mounted() {
            this.numberGrow(this.$refs.numberGrow)
        },
        methods: {
            numberGrow(ele) {
                let _this = this
                let step = Math.ceil((_this.value * 10) / (_this.time * 1000))
                let current = 0
                let start = 0
                let t = setInterval(function () {
                    start += step
                    if (start > _this.value) {
                        clearInterval(t)
                        start = _this.value
                        t = null
                    }
                    if (current === start) {
                        return
                    }
                    current = start
                    ele.innerHTML = current
                    // .toString().replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, '$1,') 逗号隔开数字，
                }, 10)
            }

        }
    }
</script>
<style scoped>
    /* @import url(); 引入css类 */
    .number-grow-warp {
        transform: translateZ(0);
    }

    .number-grow {
        color: rgba(30, 32, 32, 1);
        font-family: Roboto;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
    }
</style>