<!--  -->
<template>
    <div id='mobileHeader' :style='{height:(activeLi!="2001" ? "1.2rem":"0rem")}'>
        <div class='header'
            :style='{background:(isMenu||activeLi!="2001"||scrollTop ? "#fff":"rgba(255, 255, 255, 0)"),borderBottom:(isMenu||activeLi!="2001"||scrollTop ? "0.5px solid rgba(206, 212, 226, 1)":"none")}'>
            <div class='logo'>
                <img src="@/img/mobile/logoOne.png" v-if='!isMenu&&activeLi=="2001"&&!scrollTop' />
                <img src="@/img/logo.png" v-else />
            </div>
            <div class='menuClose' @click='getMenu()' v-if='isMenu'>
                <img src='@/img/mobile/colseIcon.png'>
            </div>
            <div v-else class='menu-btn' @click='getMenu()'>
                <div class='line' :style='{background:(activeLi=="2001"&&!scrollTop ? "#fff":"#000")}'></div>
                <div class='line' :style='{background:(activeLi=="2001"&&!scrollTop ? "#fff":"#000")}'></div>
                <div class='line' :style='{background:(activeLi=="2001"&&!scrollTop ? "#fff":"#000")}'></div>
            </div>
            <div v-if='isMenu' :style='{height:menuHeight+"rem"}' class='menuCont'>
                <ul class='menu' :style='{height:menuHeight-1+"rem"}'>
                    <li>
                        <div class='menuOne' @click='goHome'>
                            首页
                        </div>
                    </li>
                    <li>
                        <div class='menuOne' @click='getMenuTwo("service")'>
                            服务内容
                            <i class='dowmICon' :style="{transform:(isService?' rotate(270deg)':'')}"></i>
                        </div>
                        <div v-if='this.isService'>
                            <div class='menuTwo' @click='goService("organService")'>
                                机构运营解决方案
                            </div>
                            <div class='menuTwo' @click='goService("homeService")'>
                                家庭服务解决方案
                            </div>
                            <div class='menuTwo' @click='goService("pensionServices")'>
                                养老机构运营解决方案
                            </div>
                            <div class='menuTwo' @click='goService("governmentService")'>
                                政府项目解决方案
                            </div>
                        </div>

                    </li>
                    <li>
                        <div class='menuOne' @click='getMenuTwo("case")'>
                            案例展示
                            <i class='dowmICon' :style="{transform:(isCase?' rotate(270deg)':'')}"></i>
                        </div>
                        <div v-if='isCase'>
                            <div class='menuTwo' @click='goCase("jiulongCase")'>
                                苏州九龙医院
                            </div>
                            <div class='menuTwo' @click='goCase("xingjianCase")'>
                                苏州星健康复医院
                            </div>
                            <div class='menuTwo' @click='goCase("taikangCase")'>
                                泰康养老服务
                            </div>
                        </div>

                    </li>
                    <li>
                        <div class='menuOne' @click='getMenuTwo("about")'>
                            关于我们
                            <i class='dowmICon' :style="{transform:(isAbout?' rotate(270deg)':'')}"></i>
                        </div>
                        <div v-if='isAbout'>
                            <div class='menuTwo' @click='goAbout("companyIntro")'>
                                公司简介
                            </div>
                            <div class='menuTwo' @click='goAbout("standard")'>
                                SMTTP标准化建设
                            </div>
                            <div class='menuTwo' @click='goAbout("culture")'>
                                企业文化
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class='menuOne' @click='goCooperation()'>
                            商务合作
                        </div>
                    </li>
                </ul>
            </div>

        </div>
        <div class='mDialog' v-if='isMenu'></div>
        <div class='goTop' v-if="scrollTop" @click='goTop'>
            <img src='@/img/topIcon.png' style="display: block;width: 1rem;height: 1rem;;">
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    export default {
        data() {
            return {
                isMenu: false,
                isService: false,
                isCase: false,
                isAbout: false,
                activeLi: "2001",
                scrollTop: false,
                menuHeight: null

            }
        },
        computed: {
            ...mapState({
                currentTab: state => state.currentTab
            }),
        },
        //生命周期 - 创建完成（访问当前this实例）
        created() {
            sessionStorage.setItem("currentTab", "2001")
            this.$store.commit("SETT_CURRENTTBD", "2001")
            this.menuHeight =  window.screen.availHeight * 0.02 - 1.2
        },
        //生命周期 - 挂载完成（访问DOM元素）
        mounted() {
            this.activeLi = sessionStorage.getItem("currentTab")
            // 监听滚动事件
            window.addEventListener('scroll', () => {
                this.scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft
                const top = document.documentElement.scrollTop || document.body.scrollTop; //兼容不同的浏览器
                if (top > 10) {
                    this.scrollTop = true
                } else {
                    this.scrollTop = false
                }
            }, true);
        },
        watch: {
            $route(to) {
                if (to.path == '/m/index') {
                    this.activeLi = "2001"
                }
                else if (to.path.indexOf("/m/mService/") != -1) {
                    this.activeLi = "2002"
                } else if (to.path.indexOf("/m/case/") != -1) {
                    this.activeLi = "2003"
                } else if (to.path.indexOf("/m/about/") != -1) {
                    this.activeLi = "2004"
                } else if (to.path == '/m/cooperation') {
                    this.activeLi = "2005"
                }
            },
            'currentTab'() {
                this.activeLi = this.currentTab
            },
            "activeLi"() {
                if (this.activeLi == "2001") {
                    sessionStorage.setItem("currentTab", "2001")
                    this.$store.commit("SETT_CURRENTTBD", "2001")
                } else if (this.activeLi == "2002") {
                    sessionStorage.setItem("currentTab", "2002")
                    this.$store.commit("SETT_CURRENTTBD", "2002")
                } else if (this.activeLi == "2003") {
                    sessionStorage.setItem("currentTab", "2003")
                    this.$store.commit("SETT_CURRENTTBD", "2003")

                } else if (this.activeLi == "2004") {
                    sessionStorage.setItem("currentTab", "2004")
                    this.$store.commit("SETT_CURRENTTBD", "2004")

                } else if (this.activeLi == "2005") {
                    sessionStorage.setItem("currentTab", "2005")
                    this.$store.commit("SETT_CURRENTTBD", "2005")

                }

            },
            // "isMenu"() {
            //     if (this.isMenu) {
            //         this.$store.commit("IS_MENU", true)
            //     } else {
            //         this.$store.commit("IS_MENU", false)
            //     }
            // }
        },
        methods: {
            getMenu() {
                if (!this.isMenu) {
                    this.isMenu = true
                } else {
                    this.isMenu = false
                }
            },
            getMenuTwo(item) {
                if (item === 'service') {
                    this.isService = !this.isService
                    this.isCase = false
                    this.isAbout = false
                } else if (item === 'case') {
                    this.isService = false
                    this.isCase = !this.isCase
                    this.isAbout = false
                } else if (item === 'about') {
                    this.isService = false
                    this.isCase = false
                    this.isAbout = !this.isAbout

                }

            },
            // 首页
            goHome() {
                this.isMenu = false
                if (this.activeLi != "2001") {
                    // this.activeLi = "2001"
                    this.$router.push(
                        {
                            path: '/m/index'
                        }
                    )

                }

            },
            // 服务
            goService(item) {
                this.isMenu = false
                // if (this.activeLi != "2002") {
                // this.activeLi = "2002"
                this.$router.push(
                    {
                        path: '/m/mService/' + item
                    }
                )
                // }

            },
            // 案例
            goCase(item) {
                this.isMenu = false
                // if (this.activeLi != "2003") {
                // this.activeLi = "2003"
                this.$router.push(
                    {
                        path: '/m/case/' + item
                    }
                )
                // }

            },
            // 关于
            goAbout(item) {
                this.isMenu = false
                // if (this.activeLi != "2004") {
                // this.activeLi = "2004"
                this.$router.push(
                    {
                        path: '/m/about/' + item
                    }
                )
                // }
            },
            // 合作
            goCooperation() {
                this.isMenu = false
                if (this.activeLi != "2005") {
                    this.$router.push(
                        {
                            path: '/m/cooperation'
                        }
                    )
                }
            },
            // 回到顶部
            goTop() {
                let top = document.documentElement.scrollTop || document.body.scrollTop;
                //实现滚动效果
                const timeTop = setInterval(() => {
                    document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
                    if (top <= 0) {
                        clearInterval(timeTop);
                    }
                }, 10);
            }

        }
    }
</script>
<style scoped>
    /* @import url(); 引入css类 */
    #mobileHeader {
        height: 1.2rem;
    }

    #mobileHeader .header {
        width: 100%;
        height: 1.2rem;
        display: flex;
        justify-content: space-between;
        position: fixed;
        top: 0;
        z-index: 4444;
        background: #fff;
        box-sizing: border-box;
    }

    #mobileHeader .logo {
        padding: 0.16rem 0 0 0.32rem;
    }

    #mobileHeader .logo img {
        display: block;
        width: 2.16rem;

    }

    #mobileHeader .menu-btn {
        padding: 0.46rem 0.32rem 0 0;
    }

    #mobileHeader .menu-btn .line {
        display: block;
        width: 0.48rem;
        height: 0.04rem;
        border-radius: 10px;/*no*/
        /* background: rgba(255, 255, 255, 1); */
        background: #000;
        margin-bottom: .1rem;
    }

    #mobileHeader .header .menuClose {
        padding: 0.26rem .2rem 0 0;
    }

    #mobileHeader .header .menuClose img {
        display: block;
        width: 0.72rem;
        height: 0.72rem;
    }



    #mobileHeader .header .menuCont {
        position: absolute;
        z-index: 44444;
        top: 1.2rem;
        right: 0;
        background: #fff;
        box-sizing: border-box;
        width: 6.32rem;
    }

    #mobileHeader .header .menu {
        overflow: scroll;
        padding: 0 0.36rem 0 0.36rem;
    }

    #mobileHeader .header .menu li .menuOne {
        color: rgba(30, 32, 32, 1);
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 16px;/*no*/
        line-height: 1.2rem;
        height: 1.2rem;
        border-bottom: 0.5px solid rgba(206, 212, 226, 1);/*no*/
        position: relative;
    }

    #mobileHeader .header .menu li .menuOne .dowmICon {
        display: block;
        width: .4rem;
        height: .4rem;
        background-image: url(../../img/mobile/menuDown.png);
        background-size: .4rem .4rem;
        position: absolute;
        top: .44rem;
        right: .36rem;
    }

    #mobileHeader .header .menu li .menuTwo {
        height: 1rem;
        color: rgba(82, 83, 83, 1);
        font-family: Source Han Sans CN;
        font-size: 15px;/*no*/
        line-height: 1rem;
        border-bottom: 0.5px solid rgba(206, 212, 226, 1);/*no*/
    }

    .mDialog {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        /* background: #232020; */
        background: rgba(35, 32, 32, .6);
        z-index: 1111;
        overflow: hidden;
    }

    .goTop {
        position: fixed;
        right: .32rem;
        bottom: .5rem;
        width: 1rem;
        height: 1rem;
        z-index: 1111;
    }
</style>