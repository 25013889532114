<template>
  <div id="home">
    <div class="swiper">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <div class='bannerCont'>
            <div class='bannerCont'>
              <img src='@/img/bannerTwo.png' alt="养老服务" class='bannerBg' />
              <div class='bannerTitle'>
                <div class='bannerTip'>铸造一流的养老服务民族品牌</div>
                <ul>
                  <li class="banTwoLi"><img src='@/img/banTwoIconOne.png' class='banTwoIcon'>
                    <div class='bannerInfo'>让老龄生活充满阳光与希望</div>
                  </li>
                  <li class="banTwoLi">
                    <img src='@/img/banTwoIconTwo.png' class='banTwoIcon'>
                    <div class='bannerInfo'>扎根服务 坚守责任 创新发展 超越自我</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class='bannerCont'>
            <img src='@/img/bannerOne.png' alt="照护服务" class='bannerBg' />
            <div class='bannerTitle'>
              <div class='bannerTip' style="  color: rgba(92, 37, 37, 1);letter-spacing: 2px;">您在外奔波辛苦&nbsp;
                &nbsp;&nbsp;我帮您照顾父母 </div>
              <ul>
                <li class='banOneLi'>
                  <i></i>
                  <div class='bannerInfo'>有了我们，您可以无忧地学习
                  </div>
                </li>
                <li class='banOneLi'>
                  <i></i>
                  <div class='bannerInfo'>有了我们，您可以无忧地工作</div>
                </li>
                <li class='banOneLi'>
                  <i></i>
                  <div class='bannerInfo'>有了我们，您可以无忧地生活</div>
                </li>
              </ul>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class='bannerCont'>
            <img src='@/img/bannerThree.png' alt="服务运营" class='bannerBg' />
            <div class='bannerTitle'>
              <div class='bannerTip' style="color: rgba(0, 0, 0, 1);;letter-spacing:3.5px;margin-bottom: 40px;">
                您可信赖的优质服务运营商</div>
              <div
                style=" color: rgba(0, 0, 0, 1);font-family: Source Han Sans CN;font-size: 18px;line-height: 27px;letter-spacing: 12px;">
                千万客户的选择&nbsp;&nbsp;您的信任就是我们的动力</div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
        <div class="swiper-button-prev" slot="button-prev"></div>
        <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
    <div class='dataProcess'>
      <img src='@/img/homeDataBg.png' class='homeDataBg'>
      <ul>
        <li>
          <img src='@/img/homeDataOne.png' class='homeDataIcon'>
          <div class='liBot'>
            <div style="display: flex;align-items: center;">
              <!--   <span class="num">100</span> -->
              <NumberGrow :value='100'></NumberGrow><span class='plus'>+</span>
            </div>
            <div class='title'>大中型合作企业</div>
          </div>
        </li>
        <li class='inline'></li>
        <li>
          <img src='@/img/homeDataTwo.png' class='homeDataIcon'>
          <div class='liBot'>
            <div style="display: flex;align-items: center;;min-width: 93px;">
              <!-- <span class="num">5000 </span> -->
              <NumberGrow :value='5000'></NumberGrow>
              <span class='plus'>+</span>
            </div>
            <div class='title'>专业服务团队</div>
          </div>
        </li>
        <li class='inline'></li>
        <li>
          <img src='@/img/homeDataThree.png' class='homeDataIcon'>
          <div class='liBot'>
            <div style="display: flex;align-items: center;min-width: 152px;">
              <!-- <span class="num">1500000</span> -->
              <NumberGrow :value='1500000'></NumberGrow>
              <span class='plus'>+</span>
            </div>
            <div class='title'>总计服务人次</div>
          </div>
        </li>
        <li class='inline'></li>
        <li>
          <img src='@/img/homeDataFour.png' class='homeDataIcon'>
          <div class='liBot'>
            <div style="display: flex;align-items: center;">
              <NumberGrow :value='20'></NumberGrow>
              <!-- <span class="num">20</span> -->
              <span class='plus'>+</span>
            </div>
            <div class='title'>覆盖城市</div>
          </div>
        </li>
      </ul>
    </div>
    <div class='service'>
      <div class='serviceTitle'>我们的服务</div>
      <div class='servicecontent'>
        <div class='serviceLeft'>
          <div style="margin: 0 0 23px 30px;">
            <div
              style=" font-size: 24px;font-family: Source Han Sans CN-Bold, Source Han Sans CN;font-weight: bold;color: #1E2020;line-height: 40px;margin-bottom: 2px;">
              定制化服务</div>
            <div class='lineIcon'>
              <span class='lineLeft'></span>
              <span class='lineRight'></span>
            </div>
            <div style="font-size: 13px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #515252;
          line-height: 22px;margin: 10px 20px 0 0;">
              多元化产品设计，打通养老服务综合型产业链，全周期订制化服务供应
            </div>
          </div>
          <ul>
            <li v-for='(item,index) in serviceList' :key='index' @click='handleClick(index)'
              :class='[activeNo==index ? "activeLi":"" ]'>
              <span class='activeLiSpan'></span>
              <span class='num'>{{item.id}}</span>
              <span class='title'>{{item.name}}</span>
              <i></i>
            </li>
          </ul>
        </div>
        <div class='serviceRight'>
          <div class='serviceRightBlock' :style='{left:-imgLeft+"rem"}'>
            <div class='serviceRightCont'>
              <div class='img'>
                <img src="@/img/ourServieOne.png" alt="生活照护">
              </div>
              <div class='content'>为各类家庭，提供订制化照护方案，满足家庭服务场景中的多元化需求，匹配专业化人员提供服务。</div>
              <div class='button' @click='getDialog()'>定制服务
              </div>
            </div>
            <div class='serviceRightCont'>
              <div class='img'>
                <img src="@/img/ourServieTwo.png" alt="辅医服务">
              </div>
              <div class='content'>辅医服务在医院各科室领导与指导下，承担患者生活护理和部分简单的基础护理工作，24小时照护患者，保障患者安全。</div>
              <div class='button' @click='getDialog()'>定制服务
              </div>
            </div>
            <div class='serviceRightCont'>
              <div class='img'>
                <img src="@/img/ourServieThree.png" alt="康复护理">
              </div>
              <div class='content'>康复师指导和配合功能训练，使病人尽快能够生活自理，获得劳动的能力，走向生活，走向社会。</div>
              <div class='button' @click='getDialog()'>定制服务
              </div>
            </div>
            <div class='serviceRightCont'>
              <div class='img'>
                <img src="@/img/ourServieFour.png" alt="心理辅导">
              </div>
              <div class='content'>给予情感支持，激励摆脱生活困境的信心和勇气，更全面准确地了解自己，从而获得积极情感的体验。</div>
              <div class='button' @click='getDialog()'>定制服务
              </div>
            </div>
            <div class='serviceRightCont'>
              <div class='img'>
                <img src="@/img/ourServieFive.png" alt="健康管家">
              </div>
              <div class='content'>无论您是作为个人用户，还是家庭用户，使用私人管家服务您都能够找到健康的归属。</div>
              <div class='button' @click='getDialog()'>定制服务
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div class='solution'>
      <img src='@/img/solutionBgOne.png' class='solutionBg solutionBgOne'>
      <img src='@/img/solutionBgTwo.png' class='solutionBg solutionBgTwo'>
      <div class='solutionTitle'>解决方案</div>
      <div class='solutionInfo'>专业团队支持，优质资源匹配，多维度方案保障，尊享无忧服务</div>
      <div class='solutionTab'>
        <ul>
          <li v-for='(item,index) in solutionList' :key='index' @click='selectTab(index)' @mouseenter='selectTab(index)'
            :class="[solutionActive==index ? 'solutionActiveLi':'']">
            {{item.name}}
          </li>
        </ul>
      </div>
      <div class='solutionCont'>
        <div style=" transition:all 1s;position: absolute;background: #FFFFFF"
          :style="{top:(solutionActive=='0'?'0':'500px')}">
          <div v-if='solutionActive=="0"' class='solutionBox'>
            <div class='solutionContLeft'>
              <img src='@/img/solutionPicOne.png' alt="机构运营">
            </div>
            <div class='solutionContRight'>
              <div class='rightTitle'>机构运营解决方案</div>
              <div class='lineIcon'>
                <span class='lineLeft'></span>
                <span class='lineRight'></span>
              </div>
              <div class='rightInfo'>
                优质的服务运营商……
              </div>
              <ul>
                <li class='widthLi'>
                  <i class='tabIcon'></i>
                  <span>多元化服务模式</span>
                </li>
                <li>
                  <i class='tabIcon'></i>
                  <span>专业度高</span>
                </li>
                <li class='widthLi'>
                  <i class='tabIcon'></i>
                  <span> 规范化运作</span>
                </li>
                <li>
                  <i class='tabIcon'></i>
                  <span>安全可靠</span>
                </li>
              </ul>
              <div class='more' @click='goService("organService")'>
                <span>查看详情</span>
                <i class="moreButton"></i>
              </div>
            </div>
          </div>
        </div>
        <div style="transition:all 1s;position: absolute;display: flex;background: #FFFFFF"
          :style="{top:(solutionActive=='1'?'0':'500px')}">
          <div v-if='solutionActive=="1"' class='solutionBox'>
            <div class='solutionContLeft'>
              <img src='@/img/solutionPicTwo.png' alt="家庭运营">
            </div>
            <div class='solutionContRight'>
              <div class='rightTitle'>家庭运营解决方案</div>
              <div class='lineIcon'>
                <span class='lineLeft'></span>
                <span class='lineRight'></span>
              </div>
              <div class='rightInfo'>
                优选服务，全家的福音……
              </div>
              <ul>
                <li class='widthLi'>
                  <i class='tabIcon'></i>
                  <span>短、长期专业护理服务</span>
                </li>
                <li>
                  <i class='tabIcon'></i>
                  <span>术后康复</span>
                </li>
                <li class='widthLi'>
                  <i class='tabIcon'></i>
                  <span> 失能失智老人生活照护</span>
                </li>
                <li>
                  <i class='tabIcon'></i>
                  <span>慢病专病照料</span>
                </li>
              </ul>
              <div class='more' @click='goService("homeService")'>
                <span>查看详情</span>
                <i class="moreButton"></i>
              </div>
            </div>
          </div>
        </div>
        <div style="transition:all 1s;position: absolute;display: flex;background: #FFFFFF;"
          :style="{top:(solutionActive=='2'?'0':'500px')}">
          <div v-if='solutionActive=="2"' class='solutionBox'>
            <div class='solutionContLeft'>
              <img src='@/img/solutionPicThree.png' alt="政府服务">
            </div>
            <div class='solutionContRight'>
              <div class='rightTitle'>政府服务解决方案</div>
              <div class='lineIcon'>
                <span class='lineLeft'></span>
                <span class='lineRight'></span>
              </div>
              <div class='rightInfo'>
                深耕服务，创新发展，放心的养老服务品牌……
              </div>
              <ul>
                <li class='widthLi'>
                  <i class='tabIcon'></i>
                  <span>居家养老上门服务</span>
                </li>
                <li>
                  <i class='tabIcon'></i>
                  <span>养老、护理机构托管运营</span>
                </li>
                <li class='widthLi'>
                  <i class='tabIcon'></i>
                  <span> 民政、残联等条线<br />创新服务</span>
                </li>
                <li>
                  <i class='tabIcon'></i>
                  <span>信息化运营开发</span>
                </li>
              </ul>
              <div class='more' @click='goService("governmentService")'>
                <span>查看详情</span>
                <i class="moreButton"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='train'>
      <img src='@/img/trainBgOne.png' class='trainBg trainBgOne'>
      <img src='@/img/trainBgTwo.png' class='trainBg trainBgTwo'>
      <div class='trainTitle'>高能培训</div>
      <div class='trainInfo'>服务承诺做有保障的专业照护服务品牌</div>
      <div class='trainCont'>
        <div class='trainContLeft'>
          <ul class="ulO" style="animation: mymove 20s linear infinite;width:5216px;">
            <li v-for="(item,index) in trainList" :key='index'>
              <img :src='item.imgUrl' style="width: 302px;height: 210px;display: block;" />
              <!-- <div style="width: 260px;height: 180px;background: #fff;">{{item}}</div> -->
            </li>
          </ul>
        </div>
        <div class='trainContRight'>
          <div class='title'>
            · 培训体系 ·
          </div>
          <div class='lineIcon'>
            <span class='lineLeft'></span>
            <span class='lineRight'></span>
          </div>
          <div
            style="color: #6b2a2a;font-family: Source Han Sans CN;font-size: 16px;line-height: 167%;padding: 0 24px;">
            <!-- <div style="margin-bottom:10px;">日式介护+三甲医院团队共同打造培训体系</div> -->
            <div style="margin-bottom:20px;">分病种标准化操作、严格遵守服务流程、专业打造安心服务、提升技能同时保证服务质量
            </div>
          </div>
          <ul>
            <li>
              <i class='rightIcon'></i>
              <span style="display: block;width: 202px;">定期参加国家认证专业培训</span>
            </li>
            <li>
              <i class='rightIcon'></i>
              <span>养老护理员证书</span>
            </li>
            <li>
              <i class='rightIcon'></i>
              <span style="display: block;width: 202px;">团队拥有医疗辅助护理员</span>
            </li>
            <li>
              <i class='rightIcon'></i>
              <span>定期技能考查</span>
            </li>
          </ul>
          <!-- border-radius: 50%; -->
          <div class='lineCont' style="">
            <div class="lineInfo" style="">

            </div>
          </div>

        </div>
      </div>
      <!-- <div class="tech-slideshow">
        <div class="mover-1">
        </div>
        <div class="mover-2">
        </div>
      </div> -->
    </div>
    <div class='programme'>
      <div class='programmeTitle'>个性化定制</div>
      <ul>
        <li @mouseenter='selectProgramme("1")'>
          <div :class='[programmeActive=="1" ? "programmeActiveLi":"programmeDiv"]'
            style="transition: width .4s;background-color: #FFF;">
            <div class='liTop'>
              <img src='@/img/assessmentIconActive.png' v-if='programmeActive=="1" '>
              <img src='@/img/assessmentIcon.png' v-else>
              <div>| 评估方案 |</div>
            </div>
            <div class='liBottom'>
              45项评估标准，<br /> 打通服务前、服务中、服务后<br />全周期服务评估，<br />为订制化服务保驾护航<br />一切用心都是为了更好的用户体验<br />
            </div>
          </div>

        </li>
        <li @mouseenter='selectProgramme("2")'>
          <div :class='[programmeActive=="2" ? "programmeActiveLi":"programmeDiv"]'
            style="transition: width .4s;background-color: #FFF;">
            <div class='liTop'>
              <img src='@/img/solveIconActive.png' v-if='programmeActive=="2" '>
              <img src='@/img/solveIcon.png' v-else>
              <div>| 解决方案 |</div>
            </div>
            <div class='liBottom'>
              千万家庭服务经验，万千真实客户反馈，<br /> 为客户量身定制医养照护方案，<br /> 一次性购买，费用更低，<br /> 自主选择时段，<br /> 海量照护师线上匹配，质量更高。
            </div>
          </div>
        </li>
        <li @mouseenter='selectProgramme("3")'>
          <div :class='[programmeActive=="3" ? "programmeActiveLi":"programmeDiv"]'
            style="transition: width .4s;background-color: #FFF;">
            <div class='liTop'>
              <img src='@/img/customizedIconActive.png' v-if='programmeActive=="3" '>
              <img src='@/img/customizedIcon.png' v-else>
              <div>| 定制方案 |</div>
            </div>
            <div class='liBottom'>
              海量照护师，线上标签展示，<br />根据评估师评估结果，<br />根据不同诉求匹配您最满意的照护师。<br />一对一管家式服务，尊享专业护理给您带<br />来的优质健康生活<br />
            </div>
          </div>

        </li>
      </ul>
    </div>
    <div class='advantage'>
      <div class='advantageTitle'>优势</div>
      <div class='advantageInfo'>45项专业标准&nbsp;&nbsp; 500课时专职培训</div>
      <ul>
        <li style="animation-duration: 1s;animation-name: fadeInUp;">
          <img src='@/img/safeIcon.png'>
          <div class='advantageLine'></div>
          <div class='liTitle'>更安全</div>
          <div class='liinfo'>100%自营员工</div>
        </li>
        <li style="animation-duration: 1s;animation-name: fadeInUp;">
          <img src='@/img/majorIcon.png'>
          <div class='advantageLine'></div>
          <div class='liTitle'>更专业</div>
          <div class='liinfo'>专业培训、持证上岗</div>
        </li>
        <li style="animation-duration: 1s;animation-name: fadeInUp;">
          <img src='@/img/easeIcon.png'>
          <div class='advantageLine'></div>
          <div class='liTitle'>更放心</div>
          <div class='liinfo'>三证齐全、严格审查</div>
        </li>
        <li style="animation-duration: 1s;animation-name: fadeInUp;">
          <img src='@/img/responsibleIcon.png'>
          <div class='advantageLine'></div>
          <div class='liTitle'>更负责</div>
          <div class='liinfo'>随叫随到、全程处理</div>
        </li>
      </ul>
    </div>
    <div class='informationize'>
      <div
        style="display:flex;align-items: center;font-family: Source Han Sans CN-Bold, Source Han Sans CN;font-weight: bold;color: #FFFFFF;margin-bottom: 14px;">
        <span style="font-size: 24px;line-height: 167.000008%;">机构照护服务信息化管理开创者 </span>
        <span style="font-size: 30px; line-height: 167.000008%;;margin-left: 26px;">开启数字化管理时代</span>
      </div>
      <ul>
        <li>
          <i></i>
          <span>16大管理模块</span>
        </li>
        <li>
          <i></i>
          <span>打通服务闭环</span>
        </li>
        <li>
          <i></i>
          <span> 更便捷、精准、高效</span>
        </li>
      </ul>
      <div @click='getDialog()'
        style="width: 128px;height: 44px;background: #FFFFFF;border-radius: 4px 4px 4px 4px;font-size: 16px;
font-family: Source Han Sans CN-Medium, Source Han Sans CN;font-weight: 500;color: #F53F59;line-height: 44px;text-align: center;">
        定制服务
      </div>
    </div>
    <div class='contact'>
      <div class='weChat'>
        <div class='weChatIcon'>
          <img src='@/img/weChatIcon.png'>
          <span>微信</span>
        </div>
        <div class='qrCode'>
          <img src='@/img/codeImg/indexCode.png'>
          <div style="  color: #525353;
            font-family: Source Han Sans CN;
            font-size: 13px;
            line-height: 167.000008%;">微信公众号</div>
        </div>
      </div>
      <div class='tel'>
        <div class='telIcon'>
          <img src='@/img/telIcon.png'>
          <span>咨询</span>
        </div>
        <div class='info'>
          <div style="margin-left: 30px;">
            全国免费服务热线：
          </div>
          <div style="margin-left: 53px;">
            400-688-3939
          </div>
        </div>
      </div>

    </div>
    <Dialog v-if='isDialog' @closeDialog='closeDialog'></Dialog>
  </div>
</template>

<script>
  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import "swiper/dist/css/swiper.css";
  import Dialog from '@/components/dialog.vue'
  import NumberGrow from '@/components/numberGrow.vue'
  export default {
    name: 'Home',
    data() {
      return {
        swiperOptions: {
          loop: true,
          observer: true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents: true,//修改swiper的父元素时，自动初始化swiper
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          },
          // 显示分页
          pagination: {
            el: ".swiper-pagination",
            // clickable: true, //允许分页点击跳转
            bulletClass: 'my-bullet',
            bulletActiveClass: 'my-bullet-active',
          },
          // 前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        },
        serviceList: [
          {
            id: "1.",
            name: '生活照护'
          }, {
            id: "2.",
            name: '辅医服务'
          }, {
            id: "3.",
            name: '康复护理'
          }, {
            id: "4.",
            name: '心理辅导'
          }, {
            id: "5.",
            name: '健康管家'
          }
        ],
        activeNo: 0,
        imgLeft: 0,
        solutionList: [
          {
            id: '0',
            name: '机构运营解决方案'
          },
          {
            id: '1',
            name: '家庭服务解决方案'
          },
          {
            id: '2',
            name: '政府服务解决方案'
          }
        ],
        solutionActive: 0,
        trainList: [
          { imgUrl: require("@/img/trainOne.png") },
          { imgUrl: require("@/img/trainTwo.png") }, {
            imgUrl: require("@/img/trainThree.png")
          }, {
            imgUrl: require("@/img/trainFour.png")
          }, {
            imgUrl: require("@/img/trainFive.png")
          },
          {
            imgUrl: require("@/img/trainSix.png")
          }, {
            imgUrl: require("@/img/trainSeven.png")
          }, {
            imgUrl: require("@/img/trainEight.png")
          },
          { imgUrl: require("@/img/trainOne.png") },
          { imgUrl: require("@/img/trainTwo.png") }, {
            imgUrl: require("@/img/trainThree.png")
          }, {
            imgUrl: require("@/img/trainFour.png")
          }, {
            imgUrl: require("@/img/trainFive.png")
          },
          {
            imgUrl: require("@/img/trainSix.png")
          }, {
            imgUrl: require("@/img/trainSeven.png")
          }, {
            imgUrl: require("@/img/trainEight.png")
          }
        ],
        programmeActive: '1',
        isDialog: false
      }
    },
    components: {
      swiper,
      swiperSlide,
      Dialog,
      NumberGrow
    },
    mounted() {

    },
    methods: {
      handleClick(index) {
        if (this.activeNo != index) {
          this.activeNo = index
          this.imgLeft = 720 * index / 256
        }
      },
      selectTab(index) {
        if (this.solutionActive != index) {
          this.solutionActive = index
        }
      },
      selectProgramme(index) {
        if (this.programmeActive != index) {
          this.programmeActive = index
        }
      },
      getDialog() {
        if (!this.isDialog) {
          this.isDialog = true
        }
      },
      closeDialog(value) {
        this.isDialog = value
      },
      goService(item) {
        sessionStorage.setItem("serviceItem", JSON.stringify(item))
        this.$router.push({
          path: '/service/' + item,
        })
      }
      // selectadvantage(index) {
      //   this.advantageActive = index
      // }
    }
  }
</script>
<style>
  /* ::-webkit-scrollbar {
    width: 0;
  }
  #home{
    overflow-x: scroll;
  } */

  #home .swiper {
    width: 1920px;
    height: 440px;
  }

  #home .swiper-slide {
    /* width: 100%;
    height: 0;
    padding-bottom: 28.1%; */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  #home .swiper .bannerCont {
    width: 1920px;
    position: relative;
  }

  #home .swiper .bannerCont img.bannerBg {
    display: block;
    width: 1920px;
    height: 440px;
    /* position: absolute;
    top: 0;
    right: 0; */
  }

  #home .swiper .bannerCont .bannerTitle {
    position: absolute;
    top: 132px;
    left: 400px;
    z-index: 2;
  }

  #home .swiper .bannerCont .bannerTitle .bannerTip {
    color: rgba(255, 255, 255, 1);
    font-family: AlibabaPuHuiTi2.0-55Regular;
    font-size: 34px;
    line-height: 48px;
    letter-spacing: 3.5px;
    margin-bottom: 40px;
  }

  #home .swiper .bannerCont .bannerTitle li.banOneLi {
    display: flex;
    align-items: center;
    margin-bottom: 3px;

  }

  #home .swiper .bannerCont .bannerTitle li.banOneLi .bannerInfo {
    color: rgba(109, 21, 21, 1);
    font-family: Source Han Sans CN;
    font-size: 18px;
    line-height: 36px;
  }

  #home .swiper .bannerCont .bannerTitle li.banOneLi i {
    display: block;
    width: 17px;
    height: 17px;
    background-image: url(../img/banOneIcon.png);
    background-size: 17px 17px;
    margin-right: 10px;
  }

  #home .swiper .bannerCont .bannerTitle ul li.banTwoLi {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  #home .swiper .bannerCont .bannerTitle ul li.banTwoLi img.banTwoIcon {
    display: block;
    width: 21px;
    height: 22px;
    margin-right: 16.5px;

  }

  #home .swiper .bannerCont .bannerTitle ul li .bannerInfo {
    color: rgba(255, 255, 255, 1);
    font-family: Source Han Sans CN;
    font-size: 18px;
    line-height: 27px;
  }

  /* #home  .swiper-pagination .swiper-pagination-bullet {
    width: 60px;
    height: 4px;
    background: #E5E5E5;
  } */
  #home .my-bullet {
    display: inline-block;
    width: 60px;
    height: 4px;
    background: #E5E5E5;
    margin: 0 4px;
  }

  #home .my-bullet-active {
    background: rgba(216, 29, 55, 1);
  }

  /*  */
  #home .swiper-button-prev {
    width: 40px;
    height: 40px;
    background-image: url(../img/swiperPrev.png);
    background-size: 40px 40px;
    left: 208px
  }

  #home .swiper-button-next {
    width: 40px;
    height: 40px;
    background-image: url(../img/swiperNext.png);
    background-size: 40px 40px;
    right: 191px;
  }

  .swiper-button-next img {
    display: block;
    width: 40px;
    height: 40px;
  }

  #home .dataProcess {
    /* padding: 28px 414px 20px; */
    /* display: ; */
    width: 1920px;
    background: #F6F8FA;
    text-align: center;
    position: relative;
  }

  #home .dataProcess .homeDataBg {
    display: block;
    position: absolute;
    width: 1920px;
    left: 0;
    top: 0;
    z-index: 1;
  }

  #home .dataProcess ul {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    position: relative;
    z-index: 2;
    padding-left: 357px;
  }

  #home .dataProcess ul li {
    display: flex;
    justify-content: center;
  }

  #home .dataProcess ul .homeDataIcon {
    display: block;
    width: 100px;
    height: 100px;
    margin-right: 8px;

  }

  #home .dataProcess ul li .liBot {
    padding-top: 21.5px;
    text-align: left;
  }

  /* 
  #home .dataProcess ul li .liBot .num {
    color: rgba(30, 32, 32, 1);
    font-family: Roboto;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
  } */

  #home .dataProcess ul li .liBot .plus {
    color: rgba(30, 32, 32, 1);
    font-family: Roboto;
    font-size: 22px;
    line-height: 36px;
    margin-left: 4px;
  }

  #home .dataProcess .title {
    color: rgba(82, 83, 83, 1);
    font-family: Source Han Sans CN;
    font-size: 14px;
    line-height: 21px;
  }

  #home .dataProcess ul .inline {
    /* margin-top: 7px; */
    height: 37px;
    width: 1px;
    background: #E3E3E3;
    display: block;
    margin: 0 63.5px;
  }

  #home .service {
    padding: 60px 0;
    background: #FBFBFB;
    width: 1920px;
  }

  #home .service .servicecontent {
    display: flex;
  }

  #home .service .serviceTitle {
    font-size: 28px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #1E2020;
    line-height: 50px;
    margin-bottom: 42px;
    text-align: center;
  }

  #home .service .serviceLeft {
    margin: 0 18px 0 360px;
    padding: 30px 0 20px 0;
    width: 390px;
    /* height: 534px; */
    border: 1px solid #D0D0D0
  }

  #home .service .serviceLeft ul {
    /* padding: 0 10px; */
  }

  #home .service .serviceLeft ul li {
    height: 58px;
    background: rgba(116, 178, 187, 0.10000000149011612);
    margin: 0 7px 11px 7px;
    display: flex;
    align-items: center;
    padding-left: 32px;
    border: 1px solid #FFF;
    width: 344px;
    cursor: pointer;
  }

  #home .service .serviceLeft ul li.activeLi {
    border: 1px solid #30C5DB;
    margin: 0 0 10px 0;
    padding: 0;
    width: 390px;
  }

  #home .service .serviceLeft ul li.activeLi .activeLiSpan {
    display: inline-block;
    width: 7px;
    height: 60px;
    background: #54D6E7;
    margin-right: 32px;
  }

  #home .service .serviceLeft ul li .num {
    font-size: 36px;
    font-family: 江城月湖体-600W, 江城月湖体;
    font-weight: normal;
    color: #1E2020;
    line-height: 60px;
    margin: 0 30px 0 0;
    font-style: italic
  }

  #home .service .serviceLeft ul li .title {
    font-size: 18px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #1E2020;
    line-height: 68px;
  }

  #home .service .serviceRight {
    width: 720px;
    text-align: center;
    position: relative;
    overflow: hidden;
  }

  #home .service .serviceRightBlock {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    position: absolute;
    transition: .4s;
  }

  #home .service .serviceRight {}

  #home .service .serviceRight .serviceRightBlock .serviceRightCont .img {
    width: 720px;
    height: 360px;
    overflow: hidden;
    margin-bottom: 30px;
  }

  #home .service .serviceRight .serviceRightBlock .serviceRightCont img {
    width: 720px;
    height: 360px;
    display: block;
    transition: all .4s;
  }

  #home .service .serviceRight .serviceRightBlock .serviceRightCont img:hover {
    transform: scale(1.2);
  }

  #home .service .serviceRight .content {
    margin: 0 0 30px 100px;
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #515252;
    line-height: 27px;
    width: 505px;
  }

  #home .service .serviceRight .button {
    width: 144px;
    height: 44px;
    background: #F53F59;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #F53F59;
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 44px;
    text-align: center;
    margin-left: 281px;
  }

  #home .solution {
    padding: 54px 0 0;
    text-align: center;
    width: 1920px;
    position: relative;
    background: rgba(242, 247, 252, 1);
  }

  #home .solution img.solutionBg {
    position: absolute;
    z-index: 1;
  }

  #home .solution img.solutionBgOne {
    left: 0;
    bottom: 0;
    width: 476px;
    height: 418px;

  }

  #home .solution img.solutionBgTwo {
    top: 0;
    right: 0;
    width: 373px;
    height: 315px;
  }

  #home .solution .solutionTitle {
    font-size: 28px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #1E2020;
    line-height: 47px;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
  }

  #home .solution .solutionInfo {
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 400;
    color: #515252;
    line-height: 28px;
    margin-bottom: 36px;
    position: relative;
    z-index: 2;
  }

  #home .solution .solutionTab {
    width: 666px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 61px 61px 61px 61px;
    margin: 0 auto;
    cursor: pointer;
    position: relative;
    z-index: 2;
  }

  #home .solution .solutionTab ul {
    display: flex;
    justify-content: space-between;
  }

  #home .solution .solutionTab ul li {
    font-size: 18px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #040404;
    line-height: 50px;
  }

  #home .solution .solutionTab ul li:first-child {
    padding-left: 40px;
  }

  #home .solution .solutionTab ul li:last-child {
    padding-right: 40px;
  }

  /* 
  #home .solution .solutionTab ul li:hover {
    background: #F53F59;
    border-radius: 61px 61px 61px 61px;
    padding: 0 40px;
    color: #FFFFFF;
  } */
  #home .solution .solutionTab ul .solutionActiveLi {
    background: #F53F59;
    border-radius: 61px 61px 61px 61px;
    padding: 0 40px;
    color: #FFFFFF;
  }

  #home .solution .solutionCont {
    width: 1180px;
    height: 398px;
    /* background: #FFFFFF; */
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin: 60px auto 0;
    position: relative;
    z-index: 2;
  }

  #home .solution .solutionCont .solutionBox {
    display: flex;
    position: relative;
    height: 338px;
  }


  #home .solution .solutionCont .solutionContLeft {
    position: absolute;
    left: 8px;
    top: 10px;
    width: 607px;
    height: 318px;
    overflow: hidden;

  }

  #home .solution .solutionCont .solutionContLeft img {
    width: 607px;
    height: 318px;
    display: block;
    transition: all 1s;
  }

  #home .solution .solutionCont .solutionContLeft img:hover {
    transform: scale(1.2);
  }

  #home .solution .solutionCont .solutionContRight {
    text-align: left;
    padding-top: 24px;
    margin-left: 655px;
    position: relative;
  }

  #home .solution .solutionCont .solutionContRight .rightTitle {
    font-size: 24px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #1E2020;
    line-height: 48px;

  }

  #home .solution .solutionCont .solutionContRight .rightInfo {
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #898F90;
    line-height: 33px;
    margin: 10px 0 30px 0;
  }

  #home .solution .solutionCont .solutionContRight ul {
    display: flex;
    flex-wrap: wrap;
    width: 525px;
  }

  #home .solution .solutionCont .solutionContRight ul li {
    display: flex;
    /* align-items: center; */
    margin-bottom: 20px;
  }

  #home .solution .solutionCont .solutionContRight ul .widthLi {
    width: 240px;
  }

  #home .solution .solutionCont .tabIcon {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(../img/tagIcon.png);
    background-size: 24px 24px;
    margin: 4px 10px 0 0;
  }

  #home .solution .solutionCont .solutionContRight ul li span {
    font-size: 17px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 500;
    color: #515252;
    line-height: 30px;
  }

  #home .solution .solutionCont .solutionContRight .more {
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: bold;
    color: #D71C37;
    line-height: 27px;
    border-bottom: 1px solid #D71C37;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    position: absolute;
    bottom: 39px;
    right: 54px;
  }

  #home .solution .solutionCont .solutionContRight .moreButton {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(../img/serviceMore.png);
    background-size: 24px 24px;
    /* margin-right: 4px; */
  }

  /* #home .solution .solutionCont .solutionContRight .button {
    width: 48px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 32px 32px 32px 32px;
    position: absolute;
    right: -24px;
    top: 153px;
    text-align: center;
    font-size: 30px;
    line-height: 32px;
    color: #515252;
    border: 2px solid #515252;
  }

  #home .solution .solutionCont .solutionContRight .button:hover {
    border: 2px solid #F53F59;
    color: #F53F59;
  } */

  #home .train {
    padding: 60px 0 89px 97px;
    width: 1823px;
    position: relative;
    z-index: 11;
    background: rgba(16, 49, 77, 1);
  }

  #home .train .trainBg {
    position: absolute;
    z-index: -1;
  }

  #home .train .trainBgOne {
    width: 407px;
    height: 539px;
    top: 0;
    left: 0;
  }

  #home .train .trainBgTwo {
    width: 595px;
    height: 539px;
    right: 0px;
    top: 0;
  }

  #home .trainTitle {
    text-align: center;
    font-size: 28px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 50px;
    margin-bottom: 20px;
  }

  #home .trainInfo {
    text-align: center;
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 28px;
    margin-bottom: 45px;
  }

  #home .trainCont {
    display: flex;
  }

  #home .trainCont .trainContLeft {
    width: 1266px;
    overflow: hidden;
    margin-right: 20px;
    padding-top: 20px;
  }

  #home .trainCont .trainContLeft ul {
    overflow: hidden;
  }

  #home .trainCont .trainContLeft ul li {
    margin-right: 24px;
    width: 302px;
    height: 210px;
    float: left;
    overflow: hidden;

  }

  /* #home .trainCont .trainContLeft ul li img:hover {
    transform: scale(1.5);
    overflow: hidden;
  } */

  @keyframes mymove {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -webkit-transform: translate3d(-3586px, 0, 0);
      transform: translate3d(-3586px, 0, 0);
    }

  }

  /* @-webkit-keyframes mymove

    {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -webkit-transform: translate3d(-1108px, 0, 0);
      transform: translate3d(-1108px, 0, 0);
    }
  } */

  /* @keyframes move {
    from {
      left: 0px;
    }

    to {
      left: 1108px;
    }
  } */

  /* @-webkit-keyframes move */

  /*Safari and Chrome*/
  /* {
    from {
      left: 0px;
    }

    to {
      left: 1108px;
    }
  } */

  #home .trainCont .trainContRight {
    width: 437px;
    /* height: 250px; */
    border-radius: 4px;
    background: #ffffff;
    position: relative;
  }

  #home .trainCont .trainContRight .title {
    width: 160px;
    height: 42px;
    background: #F53F59;
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 42px;
    text-align: center;
    position: absolute;
    left: 139px;
    top: -24px;
  }

  #home .trainCont .trainContRight .lineIcon {
    margin: 30px 0 18px 203px;

  }

  #home .trainCont .trainContRight ul {
    padding-left: 24px;
    margin-bottom: 20px;
    /* display: flex;
    flex-wrap: wrap; */
  }

  #home .trainCont .trainContRight ul li {
    display: flex;
    align-items: center;
    margin-bottom: 9px;

  }

  #home .trainCont .trainContRight ul li .rightIcon {
    display: block;
    width: 22px;
    height: 22px;
    background-image: url(../img/circularIcon.png);
    background-size: 22px 22px;
    margin-right: 10px;
  }

  #home .trainCont .trainContRight ul li span {
    color: #1e2020;
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 15px;
    line-height: 209.000015%;
  }

  #home .trainCont .trainContRight .lineCont {
    overflow: hidden;
    height: 24px;
  }

  #home .trainCont .trainContRight .lineCont .lineInfo {
    width: 500px;
    height: 60px;
    background: linear-gradient(0deg, #ffbac4 0%, #ffbac400 114.71%);
    border-radius: 50%;
    margin-left: -30px;
  }


  #home .programme {
    background: #F2F2F2;
    background-image: url(../img/programmeBg.png);
    background-size: 100% 100%;
    padding: 70px 0 90px 0;
    width: 1920px;
  }

  #home .programme .programmeTitle {
    font-size: 28px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #1E2020;
    line-height: 50px;
    text-align: center;
    margin-bottom: 80px;

  }

  #home .programme ul {
    display: flex;
    justify-content: center;
  }

  #home .programme ul li {
    width: 380px;
    height: 440px;
    background: #fff;
    text-align: center;
    position: relative;
    top: 0;
    left: 0;
    cursor: pointer;
    transition: all 5s;

  }

  #home .programme ul li .programmeDiv {
    width: 380px;
    height: 440px;
  }

  #home .programme ul .programmeActiveLi {
    position: relative;
    width: 420px;
    height: 480px;
    z-index: 1111;
    top: -20px;
    box-shadow: 0px 0px 10px 1px rgba(113, 113, 113, 0.30000001192092896);
    border: 1px solid #E9E9E9;

  }

  #home .programme ul .programmeActiveLi .liTop {
    background: #F53F59;
    padding-top: 60px;
    color: #FFFFFF;
  }

  #home .programme ul li .liTop {
    background: #F6FAFF;
    padding-top: 40px;
    height: 180px;
    font-size: 22px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #1E2020;
    line-height: 38px;
  }

  #home .programme ul li .liTop img {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }

  #home .programme ul li .liBottom {
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #898F90;
    line-height: 28px;
    padding-top: 38px;
    height: 182px;
  }

  #home .advantage {
    padding: 70px 0;
    text-align: center;
    width: 1920px;
  }

  #home .advantage .advantageTitle {
    font-size: 28px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    line-height: 50px;
    margin-bottom: 20px;
  }

  #home .advantage .advantageInfo {
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #515252;
    line-height: 33px;
    margin-bottom: 60px;
  }

  #home .advantage ul {
    display: flex;
    justify-content: center;
  }

  #home .advantage ul li {
    width: 174px;
    height: 160px;
    padding-top: 26px;
    margin: 0 64px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #D6DEDF;
    animation-fill-mode: forwards;

  }

  #home .advantage ul li img {
    display: block;
    width: 44px;
    height: 44px;
    margin: 0 auto 22px;
  }

  #home .advantage ul li .advantageLine {
    width: 142px;
    height: 2px;
    position: relative;
    background: #D6DEDF;
    margin: 0 auto;
  }

  #home .advantage ul li:hover .advantageLine:after {
    animation: lineMove .5s linear;

  }

  #home .advantage ul li .advantageLine:after {
    content: "";
    display: inline-block;
    width: 25px;
    height: 2px;
    background: #F53F59;
    position: absolute;
    left: 0;
    top: 0px;
  }

  @keyframes lineMove {
    0% {
      width: 25px
    }

    70% {
      width: 142px
    }

    71% {
      width: 0px
    }

    100% {
      width: 25px
    }
  }

  #home .advantage ul li .liTitle {
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 600;
    color: #000000;
    line-height: 23px;
    margin: 18px 0 4px 0;
  }

  #home .advantage ul li .liinfo {
    font-size: 13px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #444444;
    line-height: 23px;
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 50%, 0);
      transform: translate3d(0, 50%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }

  #home .informationize {
    padding: 50px 0 0 359px;
    height: 210px;
    background-image: url(../img/informationizeBg.png);
    background-size: 100% 100%;
    width: 1561px;
  }

  #home .informationize ul {
    display: flex;
    margin-bottom: 20px;
  }

  #home .informationize ul li {
    margin-right: 24px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 166.999996%;
    display: flex;
    align-items: center;
  }

  #home .informationize ul li i {
    display: block;
    background-image: url(../img/contentIcon.png);
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  #home .lineIcon {
    display: flex;
  }


  #home .lineIcon .lineLeft {
    display: block;
    width: 19px;
    height: 5px;
    background: linear-gradient(90deg, #F53F59 0%, #F39191 100%);
    border-radius: 13px 13px 13px 13px;
  }

  #home .lineIcon .lineRight {
    display: block;
    width: 9px;
    height: 5px;
    background: linear-gradient(84deg, #F39191 0%, #FDCFCF 100%);
    border-radius: 13px 13px 13px 13px;
    margin-left: 4px;
  }

  #home .contact {
    position: fixed;
    top: 500px;
    /* top: 0; */
    right: 8px;
    z-index: 3333;
    box-shadow: 0px 0px 15px 1px rgba(190, 187, 187, 0.25);
  }

  #home .contact .weChat {
    position: relative;
  }

  #home .contact .weChat .weChatIcon {
    width: 53px;
    height: 62px;
    padding: 10px 0 0 19px;
    background: linear-gradient(90.13deg, rgba(239, 42, 72, 1) 0.09%, rgba(255, 131, 92, 1) 104.83%, rgba(255, 135, 135, 1) 104.84%, rgba(196, 196, 196, 0) 104.85%);
  }

  #home .contact .weChat .weChatIcon img {
    display: block;
    width: 37px;
    height: 37px;
  }

  #home .contact .weChat .weChatIcon span {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 23px;
    margin-left: 3px;
  }

  #home .contact .weChat .qrCode {
    display: none;
    width: 141px;
    height: 138px;
    padding-top: 12px;
    background: #ffffff;
    box-shadow: -4px 0px 22px #d1d1d140;
    position: absolute;
    left: -151px;
    top: 0;
    text-align: center;

  }

  #home .contact .weChat .qrCode img {
    width: 104px;
    height: 104px;
    display: block;
    margin: 0 0 2px 16px;
  }

  #home .contact .weChat:hover .qrCode {
    display: block;
  }

  #home .contact .tel {
    position: relative;
  }

  #home .contact .tel .telIcon {
    width: 53px;
    height: 62px;
    padding: 10px 0 0 19px;
    background: rgba(255, 255, 255, 1);
  }

  #home .contact .tel .telIcon img {
    display: block;
    width: 37px;
    height: 37px;
  }

  #home .contact .tel .telIcon span {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #898F90;
    line-height: 23px;
    margin-left: 3px;
  }

  #home .contact .tel .info {
    padding-top: 12px;
    width: 216px;
    height: 70px;
    background: #FFFFFF;
    box-shadow: -4px 0px 22px 1px rgba(209, 209, 209, 0.25);
    border-radius: 2px 2px 2px 2px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #1E2020;
    line-height: 30px;
    position: absolute;
    left: -231px;
    top: 0;
    display: none;
  }

  #home .contact .tel:hover .info {
    display: block;
  }

  #home .contact .tel:hover .info::before {
    content: ' ';
    display: block;
    border-left: 7px solid #fff;
    border-right: 7px solid transparent;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    width: 0px;
    height: 0px;
    position: absolute;
    top: 28px;
    right: -14px;
    /* z-index: 3; */
  }

  /* .tech-slideshow {
    height: 200px;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  } */

  /* .tech-slideshow>div {
    height: 200px;
    width: 2526px;
    background: url(http://www.webhek.com/wordpress/wp-content/uploads/2018/03/collage.jpg);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform: translate3d(0, 0, 0);
  } */

  /* .tech-slideshow .mover-1 {
    animation: moveSlideshow 10s linear infinite;
  } */

  /* .tech-slideshow .mover-2 {
    opacity: 0;
    transition: opacity 0.5s ease-out;
    background-position: 0 -200px;
    animation: moveSlideshow 20s linear infinite;
  } */

  /* .tech-slideshow:hover .mover-2 {
    opacity: 1;
  }

  @keyframes moveSlideshow {
    100% {
      transform: translateX(-66.6666%);
    }
  } */
</style>