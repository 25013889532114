import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentTab: "",
    deviceType: "",
    // isOpenMenu:false

  },
  mutations: {
    SETT_CURRENTTBD(state, active) {
      state.currentTab = active
    },
    SETT_DEVICETYPE(state, active) {
      state.deviceType = active
    },
    // IS_MENU(state, active){
    //   state.isOpenMenu = active
    //   console.log("state.isOpenMenu",state.isOpenMenu)
    // }
  },
  actions: {
  },
  modules: {
  }
})
