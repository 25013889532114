<!--  -->
<template>
    <div class='wholeDialog' @click='handleDialog'>
        <div class='dialogCont'>
            <div class='dialogContLeft'>
                <img src='@/img/wholeDialogTop.png' class='wholeDialogTop'>
                <img src='@/img/wholeDialogFont.png' class='wholeDialogFont'>
                <img src='@/img/wholeDialogBot.png' class='wholeDialogBot'>
                <ul class='dialogContLeftUl'>
                    <li>极速预约</li>
                    <li>定制服务</li>
                    <li>服务保障</li>
                </ul>
            </div>
            <div class='dialogContRight'>
                <img src='@/img/logo.png' class='dialogLogo'>
                <div class='dialogContRightLine'>
                    <div class='dialogLineLeft'></div>
                    <div class='dialogLineInfo'>极速预约 享<span style="color: rgba(245, 63, 89, 1);">新人福利</span></div>
                    <div class='dialogLineRight'></div>
                </div>
                <div class='dialogCode'>
                    <div class='bjdCode' style="margin-right: 60px;">
                        <img src='@/img/codeImg/bjdApplet.png'>
                        <div>官方小程序</div>
                    </div>
                    <div class='bjdCode'>
                        <img src='@/img/codeImg/bjdSubscription.png'>
                        <div>官方微信公众号</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        //生命周期 - 创建完成（访问当前this实例）
        created() {

        },
        //生命周期 - 挂载完成（访问DOM元素）
        mounted() {
            this.stop()
        },
        // 组件卸载之后恢复滚动条滚动
        beforeDestroy() {
            this.move()
        },
        methods: {
            handleDialog() {
                this.$emit("closeDialog", false)
            },
            //禁止滚动
            stop() {
                var mo = function (e) { e.preventDefault(); };
                document.body.style.overflow = 'hidden';
                document.addEventListener("touchmove", mo, false);//禁止页面滑动
            },
            // 取消滑动限制
            move() {
                var mo = function (e) { e.preventDefault(); };
                document.body.style.overflow = '';//出现滚动条
                document.removeEventListener("touchmove", mo, false);
            }
        }
    }
</script>
<style scoped>
    /* @import url(); 引入css类 */
</style>