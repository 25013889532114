<!--  -->
<template>
    <div id='mobile-footer'>
        <ul class='footerUl'>
            <li @click='gohome()'>首页</li>
            <li @click='goService()'>服务内容</li>
            <li @click='goCase()'>案例展示</li>
            <li @click='goAbout()'>关于我们</li>
            <li @click='goCooperation()'>商务合作</li>
        </ul>
        <div
            style="font-size: 13px;line-height: 40px;color: rgba(82, 83, 83, 1);text-align: center;margin-bottom: .4rem;">
            关注我们</div>
        <div class='qrCode'>
            <div style="margin-right: 1rem;text-align: center;">
                <img src='@/img/codeImg/bjdSubscription.png'>
                <span>官方微信公众号</span>
            </div>
            <div style="text-align: center;">
                <img src='@/img/codeImg/bjdApplet.png'>
                <span>官方小程序</span>
            </div>
        </div>
        <a href="tel:400-688-3939">
            <div class='tel'>
                <i></i>
                <div>咨询电话</div>
                <div style="margin-left: 4px;color: rgba(30, 32, 32, 1);
            font-weight: 500;
            font-size: 17px;">400-688-3939</div>
            </div>
        </a>
        <div class='footerInfo'>Copyright©2019 柏嘉德（江苏）健康管理有限公司</div>
        <div class='footerInfo'>www.bygarde.com <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备18038325号-2</a></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        //生命周期 - 创建完成（访问当前this实例）
        created() {

        },
        //生命周期 - 挂载完成（访问DOM元素）
        mounted() {

        },
        methods: {
            gohome() {
                this.$router.push(
                    {
                        path: '/m/index'
                    }
                )
            },
            goService() {
                this.$router.push(
                    {
                        path: '/m/mService/organService'
                    }
                )
            },
            goCase() {
                this.$router.push(
                    {
                        path: '/m/case/jiulongCase'
                    }
                )

            },
            goAbout() {
                this.$router.push(
                    {
                        path: '/m/about/companyIntro'
                    }
                )
            },
            goCooperation() {
                this.$router.push(
                    {
                        path: '/m/cooperation'
                    }
                )
            }
        },


    }
</script>
<style scoped>
    /* @import url(); 引入css类 */
    #mobile-footer {
        padding: .44rem 0 .4rem 0;
        font-family: Source Han Sans CN;
        background: #eaf0f3;
    }

    #mobile-footer .footerUl {
        display: flex;
        justify-content: space-between;
        border-bottom: 0.5px solid rgba(193, 193, 193, 1);/*no*/
        margin: 0 .48rem .4rem .46rem;
        padding-bottom: .16rem;
    }

    #mobile-footer .footerUl li {
        color: rgba(82, 83, 83, 1);
        font-family: Source Han Sans CN;
        font-size: 14px;/*no*/
        line-height: 22px;/*no*/
    }

    #mobile-footer .qrCode {
        display: flex;
        color: rgba(51, 51, 51, 1);
        font-size: 13px;/*no*/
        line-height: 20px;/*no*/
        padding: 0 0 .4rem 1.22rem;
    }

    #mobile-footer .qrCode img {
        display: block;
        width: 2rem;
        height: 2rem;
        margin-bottom: .2rem;
    }

    #mobile-footer .tel {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 6.56rem;
        height: 1rem;
        border: 0.5px solid rgba(150, 150, 150, 1);/*no*/
        box-sizing: border-box;
        color: rgba(82, 83, 83, 1);
        font-size: 14px;/*no*/
        margin: 0 0 .4rem .44rem;
    }

    #mobile-footer .footerInfo {
        color: rgba(113, 119, 126, 0.7);
        font-family: Source Han Sans CN;
        font-weight: 300;
        font-size: 11px;/*no*/
        line-height: 167.000008%;
        text-align: center;
    }
    #mobile-footer .footerInfo a{
        color: rgba(113, 119, 126, 0.7);
    }
</style>