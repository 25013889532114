import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/service/governmentService',
    name: 'governmentService',
    meta: {
      title: '服务内容'
    },
    component: () => import(/* webpackChunkName: "service" */ '../views/service/governmentService.vue')
  },
  {
    path: '/service/homeService',
    name: 'homeService',
    meta: {
      title: '服务内容'
    },
    component: () => import(/* webpackChunkName: "service" */ '../views/service/homeService.vue')
  },
  {
    path: '/service/organService',
    name: 'organService',
    meta: {
      title: '服务内容'
    },
    component: () => import(/* webpackChunkName: "service" */ '../views/service/organService.vue')
  },
  {
    path: '/service/pensionServices',
    name: 'pensionServices',
    meta: {
      title: '服务内容'
    },
    component: () => import(/* webpackChunkName: "service" */ '../views/service/pensionServices.vue')
  },
  {
    path: '/case',
    name: 'Case',
    meta: {
      title: '案例展示'
    },
    component: () => import(/* webpackChunkName: "Case" */ '../views/case.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '关于我们'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    meta: {
      title: '商务合作'
    },
    component: () => import(/* webpackChunkName: "cooperation" */ '../views/cooperation.vue')
  },
  {
    path: '/m/index',
    name: 'index',
    meta: {
      title: '首页'
    },
    component: () => import(/* webpackChunkName: "model" */ '../views/mobile/index.vue')
  },
  {
    path: '/m/about/companyIntro',
    name: 'companyIntro',
    meta: {
      title: '关于我们'
    },
    component: () => import(/* webpackChunkName: "model" */ '../views/mobile/about/companyIntro.vue')
  },
  {
    path: '/m/about/standard',
    name: 'standard',
    meta: {
      title: '关于我们'
    },
    component: () => import(/* webpackChunkName: "model" */ '../views/mobile/about/standard.vue')
  }, {
    path: '/m/about/culture',
    name: 'standard',
    meta: {
      title: '关于我们'
    },
    component: () => import(/* webpackChunkName: "model" */ '../views/mobile/about/culture.vue')
  },
  {
    path: '/m/cooperation',
    name: 'cooperation',
    meta: {
      title: '商务合作'
    },
    component: () => import(/* webpackChunkName: "model" */ '../views/mobile/cooperation.vue')
  }, {
    path: '/m/mService/pensionServices',
    name: 'service',
    meta: {
      title: '服务内容'
    },
    component: () => import(/* webpackChunkName: "model" */ '../views/mobile/service/pensionServices.vue')
  }, {
    path: '/m/mService/organService',
    name: 'service',
    meta: {
      title: '服务内容'
    },
    component: () => import(/* webpackChunkName: "model" */ '../views/mobile/service/organService.vue')
  },
  {
    path: '/m/mService/homeService',
    name: 'service',
    meta: {
      title: '服务内容'
    },
    component: () => import(/* webpackChunkName: "model" */ '../views/mobile/service/homeService.vue')
  }, {
    path: '/m/mService/governmentService',
    name: 'service',
    meta: {
      title: '服务内容'
    },
    component: () => import(/* webpackChunkName: "model" */ '../views/mobile/service/governmentService.vue')
  }, {
    path: '/m/case/jiulongCase',
    name: 'case',
    meta: {
      title: '案例展示'
    },
    component: () => import(/* webpackChunkName: "model" */ '../views/mobile/case/jiulongCase.vue')
  }, {
    path: '/m/case/xingjianCase',
    name: 'case',
    meta: {
      title: '案例展示'
    },
    component: () => import(/* webpackChunkName: "model" */ '../views/mobile/case/xingjianCase.vue')
  }, {
    path: '/m/case/taikangCase',
    name: 'case',
    meta: {
      title: '案例展示'
    },
    component: () => import(/* webpackChunkName: "model" */ '../views/mobile/case/taikangCase.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 刷新页面，滚动条回到初始位置
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})
router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title
  if (title && to.path != '/') {
    document.title = title + '——柏嘉德官网'
  } else {
    document.title = '柏嘉德（江苏）健康管理有限公司'
  }
  if (to.path == '/' || to.path == '/case' || to.path.indexOf("/service/") != -1 || to.path == '/about' || to.path == '/cooperation') {
    // 路径为pc端，但是当前设备为移动设备.则跳转至移动端首页
    if (/AppleWebKit.*Mobile/i.test(navigator.userAgent) || (/MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/.test(navigator.userAgent))) {
      router.push({ path: '/m/index', });
      sessionStorage.setItem("device", "mobile")
    }
  } else if (to.path.indexOf("/m/") != -1) {
    // 路径为移动端，但是当前设备为pc.则跳转至pc首页
    if (! /AppleWebKit.*Mobile/i.test(navigator.userAgent) || (/MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/.test(navigator.userAgent))) {
      router.push({ path: '/', });
      sessionStorage.setItem("device", "pc")
    }
  }
  next();
})
export default router
