import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './style/public.css'
import './style/common.css'
// import VueLazyLoad from 'vue-lazyload' //图片懒加载
import 'lib-flexible'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style
// import 'swiper/css/swiper.css'
Vue.config.productionTip = false
Vue.use(VueAxios, axios)
// Vue.use(VueLazyLoad)
// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   error: 'dist/error.png',
//   loading: 'dist/loading.gif',
//   attempt: 1
// })
// Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
