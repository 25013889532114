<!-- 公共头部 -->
<template>
    <div id='header' :style='{height:(activeLi === "1002" ? "0":"0.3125rem") }'>
        <!-- <div class='content'
        :style='{left:-scrollLeft+"px", } '>  定宽1920情况下，导航条移动-->
        <div class='content'
            :style='{background:(scrollTop ? "#fff":"0"), borderBottom:(activeLi === "1002" ? "none":"1px solid #d3d3d340")   } '>
            <div class='logo' @click='goHome()'>
                <img src="../img/logo.png" />
            </div>
            <ul style="font-size: 16px;">
                <div class='activeLine' :style='{width:NavActiveWidth+"px",left:NavActiveLeft+"px",}'></div>
                <li @click='goHome()' :class='[activeLi=="1001" ? "activeLi":""]'>首页</li>
                <li :class='[activeLi=="1002" ? "activeLi":"","serviceTitle"]'>服务内容
                    <div class='serviceTab'>
                        <div class="serviceLi" @click='goService("organService")'>
                            <img src='@/img/organServiceIcon.png'>
                            <div class="serviceTip">机构运营解决方案</div>
                        </div>
                        <div class="serviceLi" @click='goService("homeService")'>
                            <img src='@/img/homeServiceIcon.png'>
                            <div class="serviceTip">家庭服务解决方案</div>
                        </div>
                        <div class="serviceLi" @click='goService("pensionServices")'>
                            <img src='@/img/pensionServicesIcon.png'>
                            <div class="serviceTip">养老机构运营解决方案</div>
                        </div>
                        <div class="serviceLi" @click='goService("governmentService")'>
                            <img src='@/img/governmentServiceIcon.png'>
                            <div class="serviceTip">政府服务解决方案</div>
                        </div>

                    </div>
                </li>
                <li @click='goCase()' :class='[activeLi=="1003" ? "activeLi":""]'>案列展示</li>
                <li @click='goAbout()' :class='[activeLi=="1004" ? "activeLi":""]'>关于我们</li>
                <li @click='goCooperation()' :class='[activeLi=="1005" ? "activeLi":""]'>商务合作</li>
            </ul>
            <div class='contact'>
                <div class='contactTel'>
                    400-688-3939
                </div>
                <div class='contactText' style="display: flex;justify-content: space-around;">
                    <span>官</span><span>方</span><span>合</span><span>作</span><span>电</span><span>话</span>
                </div>
            </div>
            <div class='appointBtn'>
                <div class='button' @mouseenter='getQrCode' @mouseleave='getQrCode'>预约服务</div>
                <div class='qrCode' v-if='isQrCode'>
                    <img src='@/img/codeImg/bjdApplet.png'>
                </div>
            </div>
        </div>
        <div v-if="scrollTop" @click='goTop' style="position: fixed;right: 8px;top:654px;z-index: 3333;">
            <img src='@/img/topIcon.png' style="display: block;width: 72px;height: 72px;">
        </div>

    </div>
</template>

<script>
    import { mapState } from "vuex"
    export default {
        data() {
            return {
                NavActiveWidth: 32,
                NavActiveLeft: 0,
                activeLi: "1001",
                isQrCode: false,
                scrollLeft: 0,
                scrollTop: false
            }
        },
        //生命周期 - 创建完成（访问当前this实例）
        created() {
            sessionStorage.setItem("currentTab", "1001")
            this.$store.commit("SETT_CURRENTTBD", "1001")
        },
        //生命周期 - 挂载完成（访问DOM元素）
        mounted() {
            this.activeLi = sessionStorage.getItem("currentTab")
            // 监听滚动事件
            window.addEventListener('scroll', () => {
                this.scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft
                // console.log("scrollLeft", this.scrollLeft)
                const top = document.documentElement.scrollTop || document.body.scrollTop; //兼容不同的浏览器
                if (top > 10) {
                    this.scrollTop = true
                } else {
                    this.scrollTop = false
                }
            }, true);
        },
        beforeDestroy() {
        },
        computed: {
            ...mapState({
                currentTab: state => state.currentTab
            }),
        },
        watch: {
            $route(to) {
                if (to.path == '/') {
                    this.activeLi = "1001"
                }
                else if (to.path.indexOf("/service/") != -1) {
                    this.activeLi = "1002"
                } else if (to.path == '/case') {
                    this.activeLi = "1003"
                } else if (to.path == '/about') {
                    this.activeLi = "1004"
                } else if (to.path == '/cooperation') {
                    this.activeLi = "1005"
                }
            },
            'currentTab'() {
                this.activeLi = this.currentTab
            },
            "activeLi"() {
                if (this.activeLi == "1001") {
                    this.NavActiveWidth = 32
                    this.NavActiveLeft = 0
                    sessionStorage.setItem("currentTab", "1001")
                    this.$store.commit("SETT_CURRENTTBD", "1001")
                } else if (this.activeLi == "1002") {
                    this.NavActiveWidth = 64
                    this.NavActiveLeft = 96
                    sessionStorage.setItem("currentTab", "1002")
                    this.$store.commit("SETT_CURRENTTBD", "1002")
                } else if (this.activeLi == "1003") {
                    this.NavActiveWidth = 64
                    this.NavActiveLeft = 224
                    sessionStorage.setItem("currentTab", "1003")
                    this.$store.commit("SETT_CURRENTTBD", "1003")

                } else if (this.activeLi == "1004") {
                    this.NavActiveWidth = 64
                    this.NavActiveLeft = 352
                    sessionStorage.setItem("currentTab", "1004")
                    this.$store.commit("SETT_CURRENTTBD", "1004")

                } else if (this.activeLi == "1005") {
                    this.NavActiveWidth = 64
                    this.NavActiveLeft = 480
                    sessionStorage.setItem("currentTab", "1005")
                    this.$store.commit("SETT_CURRENTTBD", "1005")

                }

            }

        },
        methods: {
            goHome() {
                if (this.activeLi != "1001") {
                    this.activeLi = "1001"
                    this.$router.push({
                        path: '/',
                    })
                    // if (sessionStorage.getItem("tabList")) {
                    //     let tabList = JSON.parse(sessionStorage.getItem("tabList"))
                    //     tabList.push("1001")
                    //     sessionStorage.setItem("tabList", JSON.stringify(tabList))
                    // } else {
                    //     sessionStorage.setItem("tabList", JSON.stringify(['1001']))
                    // }
                }

            },
            goService(item) {
                sessionStorage.setItem("serviceItem", JSON.stringify(item))
                // if (this.activeLi != "1002") {
                this.activeLi = "1002"
                this.$router.push({
                    path: '/service/' + item,
                    // query: {
                    //     item: item
                    // }
                })

                // if (sessionStorage.getItem("serviceItem")) {
                //     let serviceItem = JSON.parse(sessionStorage.getItem("serviceItem"))
                //     tabList.push("1002")

                // } else {
                //     sessionStorage.setItem("tabList", JSON.stringify(["1002"]))
                // }

                // }


            },
            goCase() {
                if (this.activeLi != "1003") {
                    this.activeLi = "1003"
                    this.$router.push({
                        path: '/case',
                    })
                    // sessionStorage.setItem("currentTab", "1003")
                    // this.$store.commit("SETT_CURRENTTBD", "1003")
                    // if (sessionStorage.getItem("tabList")) {
                    //     let tabList = JSON.parse(sessionStorage.getItem("tabList"))
                    //     tabList.push("1003")
                    //     sessionStorage.setItem("tabList", JSON.stringify(tabList))
                    // } else {
                    //     sessionStorage.setItem("tabList", JSON.stringify(["1003"]))
                    // }
                }



            },
            goAbout() {
                if (this.activeLi != "1004") {
                    this.activeLi = "1004"
                    this.$router.push({
                        path: '/about',
                    })
                    // sessionStorage.setItem("currentTab", "1004")
                    // this.$store.commit("SETT_CURRENTTBD", "1004")
                    // if (sessionStorage.getItem("tabList")) {
                    //     let tabList = JSON.parse(sessionStorage.getItem("tabList"))
                    //     tabList.push("1004")
                    //     sessionStorage.setItem("tabList", JSON.stringify(tabList))
                    // } else {
                    //     sessionStorage.setItem("tabList", JSON.stringify(["1004"]))
                    // }
                }



            },
            goCooperation() {
                if (this.activeLi != "1005") {
                    this.activeLi = "1005"
                    this.$router.push({
                        path: '/cooperation',
                    })
                    // sessionStorage.setItem("currentTab", "1005")
                    // this.$store.commit("SETT_CURRENTTBD", "1005")
                    // if (sessionStorage.getItem("tabList")) {
                    //     let tabList = JSON.parse(sessionStorage.getItem("tabList"))
                    //     tabList.push("1005")
                    //     sessionStorage.setItem("tabList", JSON.stringify(tabList))
                    // } else {
                    //     sessionStorage.setItem("tabList", JSON.stringify(["1005"]))
                    // }
                }

            },
            getQrCode() {
                if (!this.isQrCode) {
                    this.isQrCode = true
                } else {
                    this.isQrCode = false
                }
            },
            // 回到顶部
            goTop() {
                let top = document.documentElement.scrollTop || document.body.scrollTop;
                //实现滚动效果
                const timeTop = setInterval(() => {
                    document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
                    if (top <= 0) {
                        clearInterval(timeTop);
                    }
                }, 10);
            }
        }
    }
</script>
<style scoped>
    /* @import url(); 引入css类 */
    #header {
        width: 1920px;
    }

    #header .content {
        z-index: 8888;
        height: 80px;
        display: flex;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        border-bottom: 1px solid #d3d3d340;
    }

    #header .logo {
        padding-top: 9px;
    }

    #header .logo img {
        width: 158px;
        height: 63px;
        display: block;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
    }

    #header ul {
        margin-left: 138px;
        display: flex;
        align-items: center;
        position: relative;
    }

    #header ul li {
        margin-right: 64px;
        /*no*/
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #1E2020;
        line-height: 80px;
        cursor: pointer;
        /* -webkit-background-clip: text; */
        /* -webkit-text-fill-color: transparent; */
    }

    #header ul li.activeLi {
        color: #F53F59;
        font-weight: bold;
    }

    #header ul li:hover {
        color: #F53F59;
    }

    #header ul li.serviceTitle {
        position: relative;
    }

    #header ul li.serviceTitle:hover .serviceTab {
        display: block;
    }

    #header ul li.serviceTitle .serviceTab {
        display: none;
        position: absolute;
        padding-left: 19px;
        padding-top: 28px;
        width: 290px;
        height: 281px;
        box-sizing: border-box;
        border-radius: 2px;
        background: #ffffff;
        box-shadow: 0px 5px 12px #c7c7c740;
        top: 80px;
        left: -113px;
        color: #1e2020;
        font-family: Source Han Sans CN;
        font-size: 16px;
        line-height: 49px;
        font-weight: normal;
        z-index: 4444;

    }

    #header ul li.serviceTitle .serviceTab .serviceLi {
        width: 271px;
        height: 49px;
        letter-spacing: 1px;
        padding-left: 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin-bottom: 7px;

    }

    #header ul li.serviceTitle .serviceTab .serviceLi:hover {
        color: #f53f59;
        background: #f7f8fd;
    }

    #header ul li.serviceTitle .serviceTab .serviceLi img {
        display: block;
        width: 36px;
        height: 36px;
        margin-right: 16px;
    }

    #header ul .activeLine {
        height: 3px;
        background: linear-gradient(96deg, #EF2948 0%, #FF835C 100%, #FF8686 100%, rgba(196, 196, 196, 0) 100%);
        position: absolute;
        bottom: 2px;
        transition: all 300ms;

    }

    #header .contact {
        margin-left: 132px;
        padding-top: 14px;
    }

    #header .contactTel {
        font-size: 26px;
        font-weight: 600;
        color: #1E2020;
        line-height: 28px;
    }

    #header .contactText {
        font-size: 14px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 400;
        color: #1E2020;
        line-height: 26px;
        text-align: center;
    }

    #header .appointBtn {
        padding-top: 17px;
        margin-left: 16px;
        position: relative;
    }

    #header .appointBtn .button {
        width: 128px;
        height: 44px;
        background: #F53F59;
        box-shadow: 0px 7px 11px 1px rgba(243, 145, 145, 0.4000000059604645);
        border-radius: 4px 4px 4px 4px;
        cursor: pointer;
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 44px;
        text-align: center;
    }

    #header .appointBtn:hover .button {
        background: #D71C37;
    }

    #header .appointBtn .qrCode {
        width: 260px;
        height: 260px;
        background: #FFFFFF;
        box-shadow: 0px 4px 17px 9px rgba(192, 192, 192, 0.26);
        border-radius: 4px 4px 4px 4px;
        position: absolute;
        z-index: 1111;
        left: -172px;
        top: 76px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #header .appointBtn .qrCode img {
        width: 242px;
        height: 242px;
        display: block;
    }
</style>