<template>
  <div id="app">
    <div v-if='deviceType==="pc"'>
      <Header></Header>
      <router-view />
      <Footer></Footer>
    </div>
    <div v-if='deviceType==="mobile"'>
      <!-- :style="{height:menuHeight+'px',overflow:(isMobileMenu?'hidden':'auto')}" -->
      <mobileHeader></mobileHeader>
      <router-view />
      <mobileFooter></mobileFooter>
    </div>
  </div>
</template>
<script>
  // import { mapState } from "vuex"
  import Footer from '@/components/footer.vue'
  import Header from '@/components/header.vue'
  import mobileHeader from '@/components/mobileCom/mobileHeader.vue'
  import mobileFooter from '@/components/mobileCom/mobileFooter.vue'
  export default {
    data() {
      return {
        deviceType: 'pc',
        // isMobileMenu: false,
        // menuHeight: null
      }
    },
    components: {
      Footer: Footer,
      Header,
      mobileHeader,
      mobileFooter
    },
    // watch: {
    //   "isOpenMenu"() {
    //     this.isMobileMenu = this.isOpenMenu
    //     console.log("this.isMobileMenu", this.isMobileMenu)
    //   }

    // },
    // computed: {
    //   ...mapState({
    //     isOpenMenu: state => state.isOpenMenu
    //   }),
    // },
    created() {
      // 获取当前前的设备类型
      if (sessionStorage.getItem("device")) {
        this.deviceType = sessionStorage.getItem("device")
      }
      if (this.deviceType === "mobile") {
        document.documentElement.style.fontSize = document.documentElement.clientWidth / 7.5 + 'px';
      }
      this.menuHeight = window.screen.availHeight
    },
    mounted() {
      this.deviceType = sessionStorage.getItem("device")
      // 判断当前设备类型（移动 or PC）
      // if (this.isMobile()) {
      //   this.deviceType = 'mobile'
      //   sessionStorage.setItem("device", this.deviceType)
      //   // if (this.deviceType === this.beforeDevice) {
      //   //   // 刷新前后设备类型一致,跳至原来页面
      //   //   sessionStorage.setItem("device", this.deviceType)
      //   // } else {
      //   //   // 刷新前后设备类型不一致，或者首次进入，则给定跳转路径
      //   //   this.$router.push({ path: '/m/index', })//设备为，则跳转至移动端首页
      //   //   sessionStorage.setItem("device", this.deviceType)
      //   // }
      // } else {
      //   this.deviceType = 'pc'
      //   sessionStorage.setItem("device", this.deviceType)
      //   // if (this.deviceType === this.beforeDevice) {
      //   //   sessionStorage.setItem("device", this.deviceType)
      //   // } else {
      //   //   // 刷新前后设备类型不一致，或者首次进入，则给定跳转路径
      //   //   this.$router.push({ path: '/', }) //设备为PC，则跳转至home
      //   //   sessionStorage.setItem("device", this.deviceType)
      //   // }
      // }
    },
    destroyed() {
    },
    // watch: {
    //   $route(to) {
    //     if (to.path == '/') {
    //       if (this.deviceType === 'mobile') {
    //         this.$router.replace({ path: '/m/index', })
    //       } else if (this.deviceType === 'pc') {
    //         this.$router.replace({ path: '/', })
    //       }
    //     }
    //   },
    // },
    methods: {
      // // 判断设备类型
      // isMobile() {
      //   let flag = /AppleWebKit.*Mobile/i.test(navigator.userAgent) || (/MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/.test(navigator.userAgent))
      //   return flag;
      // }
    }
  }
</script>

<style>
  #app {
    font-family: Source Sans Pro, Helvetica Neue, Arial, sans-serif;
    position: relative;
  }
</style>